<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('germplasm.characterization_info') }}</h4>
        </template>
       <template v-slot:body>
            <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('germplasm.discriptor_level')"
                  label-for="email"
                  >
                  <b-form-input
                      id="org_name"
                        v-model="search.discriptor_level"
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('germplasm.discriptor_heading')"
                    label-for="email"
                    >
                    <b-form-input
                        id="org_name"
                          v-model="search.discriptor_heading"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('germplasm.code_guide')"
                      label-for="email"
                      >
                      <b-form-input
                          id="org_name"
                            v-model="search.code_guide"
                          ></b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Organization" vid="org_id">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="org_id"
                      >
                      <template v-slot:label>
                        {{$t('org_pro.organization')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="12">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
      </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('germplasm.characterization_info') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                              <b-button variant="primary" @click="pdfExport" class="mr-2">
                              {{  $t('globalTrans.export_pdf') }}
                              </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id= "6">
                                  {{ $t('germplasm.characterization_info')}}
                                </list-report-head>
                              </b-col>
                              </b-row>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                 <b-table bordered hover :items="characterizationList" :fields="columns" thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                   <template v-slot:cell(accession_number)="data">
                                    {{ $n(data.item.accession_number, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(distribute_quantity)="data">
                                    {{ $n(data.item.distribute_quantity, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, characterizationInfo } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        discriptor_level: '',
        discriptor_heading: '',
        code_guide: '',
        org_id: this.$store.state.dataFilters.orgId
      },
      characterizationList: [],
      excelData: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false
    }
  },
  computed: {
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('germplasmReport.accession_number'), class: 'text-left' },
          { label: this.$t('germplasmReport.crop_type'), class: 'text-left' },
          { label: this.$t('germConfig.cropName'), class: 'text-left' },
          { label: this.$t('germConfig.scientific_name'), class: 'text-left' },
          { label: this.$t('germConfig.english_name'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'accession_number' },
          { key: 'crop_type_bn' },
          { key: 'crop_name_bn' },
          { key: 'scientific_name_bn' },
          { key: 'english_name_bn' }
        ]
      } else {
        keys = [
           { key: 'index' },
          { key: 'accession_number' },
          { key: 'crop_type' },
          { key: 'crop_name' },
          { key: 'scientific_name' },
          { key: 'english_name' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
        const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return tmpData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text }
            }
          })
      }
  },
  watch: {},
  created () {
  },
  mounted () {
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.organizationData()
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === 3)

      RestApi.getData(seedFertilizerServiceBaseUrl, reportHeadingList + '/' + 6, { org_id: 3 }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    },
   loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, characterizationInfo, this.search).then(response => {
        if (response.success) {
          this.characterizationList = this.formatData(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.characterizationList = []
      })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(doc => doc.value === parseInt(item.crop_name_id))
            const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(doc => doc.value === parseInt(cropNameObj.crop_type_id))
            const customiseData = {}
            if (typeof cropTypeObj !== 'undefined') {
              customiseData.crop_type = cropTypeObj.text_en
              customiseData.crop_type_bn = cropTypeObj.text_bn
            } else {
              customiseData.crop_type = ''
              customiseData.crop_type_bn = ''
            }
            if (typeof cropNameObj !== 'undefined') {
              customiseData.crop_name = cropNameObj.text_en
              customiseData.crop_name_bn = cropNameObj.text_bn
              customiseData.english_name = cropNameObj.english_name
              customiseData.english_name_bn = cropNameObj.english_name_bn
              customiseData.scientific_name = cropNameObj.scientific_name
              customiseData.scientific_name_bn = cropNameObj.scientific_name_bn
            } else {
              customiseData.crop_name = ''
              customiseData.crop_name_bn = ''
              customiseData.english_name = ''
              customiseData.english_name_bn = ''
              customiseData.scientific_name = ''
              customiseData.scientific_name_bn = ''
            }
            return Object.assign({}, item, customiseData)
      })
      return formattedData
    },
    pdfExport () {
      const rowData = this.getPdfData()

      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germplasmReport.accession_number'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germplasmReport.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.cropName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.scientific_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.english_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Characterization Info' : 'বৈশিষ্ট্য সম্পর্কিত তথ্য'
      const columnWids = ['10%', '15%', '15%', '20%', '20%', '20%']
      ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, reportHeadingList, 3, reportTitle, rowData, columnWids)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'accession_number' : 'accession_number' },
          { key: this.$i18n.locale === 'en' ? 'crop_type' : 'crop_type_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'scientific_name' : 'scientific_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'english_name' : 'english_name_bn' }
        ]
      var serial = 0
      const listData = this.characterizationList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'accession_number') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
